import { render, staticRenderFns } from "./HtmlMessage.vue?vue&type=template&id=552e6740&scoped=true"
import script from "./HtmlMessage.vue?vue&type=script&lang=js"
export * from "./HtmlMessage.vue?vue&type=script&lang=js"
import style0 from "./HtmlMessage.vue?vue&type=style&index=0&id=552e6740&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "552e6740",
  null
  
)

export default component.exports