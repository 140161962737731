<template>
  <v-navigation-drawer v-model="isOpen" app right :width="$vuetify.breakpoint.mobile ? '95%' : '50%'" temporary
    disable-route-watcher>
    <v-card min-height="100" flat tile :loading="isLoading">
      <!--      loading-->

      <!--      <v-toolbar-->
      <!--          flat-->
      <!--          dense-->
      <!--      >-->
      <!--        &lt;!&ndash;        absolute width="100%"&ndash;&gt;-->
      <!--        <v-btn icon @click="isOpen = !isOpen">-->
      <!--          <v-icon>mdi-close</v-icon>-->
      <!--        </v-btn>-->
      <!--      </v-toolbar>-->
      <template v-if="entityData">
        <div class="d-flex pa-4">
          <entity-header :entity-data="entityData" show-permalink-button class=" flex-grow-1" />
          <v-btn icon @click="isOpen = !isOpen">
            <v-icon>mdi-close</v-icon>
          </v-btn>

        </div>
        <v-divider class="ma-3" />
        <entity-new :data="entityData" />

      </template>
    </v-card>
    <div v-if="entityData" style="z-index: 99999;position: relative;">
      <beautiful-chat :participants="participants" :titleImageUrl="titleImageUrl" :onMessageWasSent="sendMessage"
        :messageList="messageList" :newMessagesCount="newMessagesCount" :isOpen="isChatOpen" :close="closeChat"
        :icons="icons" :placeholder="placeholder" :open="openChat" :showEmoji="false" :showFile="false"
        :showEdition="true" :showDeletion="true" :showTypingIndicator="showTypingIndicator" :showLauncher="true"
        :showCloseButton="true" :colors="colors" :alwaysScrollToBottom="alwaysScrollToBottom"
        :disableUserListToggle="true" :messageStyling="false" @onType="handleOnType" @edit="editMessage">
      </beautiful-chat>
    </div>
  </v-navigation-drawer>

</template>

<script>

import { mapActions, mapGetters, mapMutations } from "vuex";
import { url } from "@/url";
import EntityNew from "@/components/Entity/EntityNew.vue";
import { entityTypeFromId, sleep } from "@/util";
import { api } from "@/api";
import EntityHeader from "@/components/Entity/EntityHeader.vue";
import axios from "axios";
import { marked } from "marked";
marked.setOptions({
  sanitize: true, // Prevents raw HTML injection
});
export default {
  name: "Template",
  components: {
    EntityNew,
    EntityHeader,
  },
  props: {},
  data() {
    return {
      foo: 42,
      entityData: null,
      isLoading: false,
      participants: [
        {
          id: 'user1',
          name: 'me',
          imageUrl: 'https://avatars3.githubusercontent.com/u/1915989?s=230&v=4'
        },
        {
          id: 'user2',
          name: 'assistant',
          imageUrl: 'https://avatars3.githubusercontent.com/u/37018832?s=200&v=4'
        }
      ], // the list of all the participant of the conversation. `name` is the user name, `id` is used to establish the author of a message, `imageUrl` is supposed to be the user avatar.
      titleImageUrl: '/img/paperify-logo-icon-black-and-white.92597dd9-2.png',
      messageList: [], // the list of the messages to show, can be paginated and adjusted dynamically
      newMessagesCount: 0,
      isChatOpen: true, // to determine whether the chat window should be open or closed
      showTypingIndicator: '', // when set to a value matching the participant.id it shows the typing indicator for the specific user
      colors: {
        header: {
          bg: '#4e8cff',
          text: '#ffffff'
        },
        launcher: {
          bg: '#4e8cff'
        },
        messageList: {
          bg: '#ffffff'
        },
        sentMessage: {
          bg: '#4e8cff',
          text: '#ffffff'
        },
        receivedMessage: {
          bg: '#eaeaea',
          text: '#222222'
        },
        userInput: {
          bg: '#f4f7f9',
          text: '#565867'
        }
      }, // specifies the color scheme for the component
      alwaysScrollToBottom: true, // when set to true always scrolls the chat to the bottom when new events are in (new message, user starts typing...)
      messageStyling: true, // enables *bold* /emph/ _underline_ and such (more info at github.com/mattezza/msgdown)


    }
  },
  computed: {
    ...mapGetters([

      "entityType",
    ]),
    placeholder: function () {
      return this.userCredit
        == 'time' ? 'Ask anything' : (this.userCredit > 0 ?
          'Ask anything, you have ' + this.userCredit + ' credit(s) left' : 'please buy credit')
    }
    ,
    ...mapGetters("user", [
      "userId",
      "userCredit",
    ]),

    id() {
      return url.getZoom(this.$route)
    },
    myEntityType() {
      if (!this.id) return
      return entityTypeFromId(this.id)
    },
    isOpen: {
      get() {
        return !!this.id
      },
      set(to) {
        !to && url.setZoom(undefined)
      }
    }
  },

  methods: {
    ...mapMutations([
      "snackbar",
    ]),
    ...mapMutations("user", [
      "setCredit",
    ]),
    escapeHtml(text) {
      return text
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;')
        .replace(/'/g, '&#039;');
    },
    ...mapActions([]),
    ...mapActions("user", []),
    async getEntityData() {
      if (!this.id) {
        this.entityData = null
        return
      }
      this.isLoading = true
      // console.log("EntityDrawer getEntityData() loading", this.isLoading)
      this.entityData = await api.get(this.id)
      console.log('hh', this.entityData)
      if (this.userId) {
        this.messageList = this.messageList.filter(i => {
          return i.data.text != 'please sign up or login.' && i.author == 'assistant'
        })
        let responseMessages = await axios.get('https://search.paperify.org/paperify_backend/public/api/messages?id=' + this.id, {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token_backend')
          }
        })

        try {
          if (responseMessages.data.messages.length) {
            responseMessages.data.messages[responseMessages.data.messages.length - 1].suggestions = responseMessages.data.messages[responseMessages.data.messages.length - 1].suggestions ? responseMessages.data.messages[responseMessages.data.messages.length - 1].suggestions : [
              "Explain Abstract of this paper",
              "Conclusions from the paper",
              "Results of the paper",
              "Methods used in this paper"
            ]
          }
          this.messageList = [...responseMessages.data.messages];
          console.log(this.messageList)
        } catch (e) {
          alert(e.message)
        }
      } else {
        this.onMessageWasSent({ author: 'assistant', type: 'html', data: { text: 'please sign up or login.' }, suggestions: [] })
      }
      // get previous messages..
      this.isLoading = false
      // console.log("EntityDrawer getEntityData() done loading", this.isLoading)
    },
    async sendMessage(message) {
      let text = message.data.text
      console.log(text.length, this.showTypingIndicator)
      if (text.length > 0 && this.showTypingIndicator != 'assistant' && this.userId) {
        this.showTypingIndicator = 'assistant'
        this.newMessagesCount = this.isChatOpen ? this.newMessagesCount : this.newMessagesCount + 1
        this.onMessageWasSent({ author: 'me', type: 'text', data: { text } })
        try {
          const response = await axios.post('https://search.paperify.org/paperify_backend/public/api/messages', {
            'text': text,
            'id': this.id,
            'data': this.entityData
          }, {
            headers: {
              'Authorization': 'Bearer ' + localStorage.getItem('token_backend')
            }
          })
          this.showTypingIndicator = '';
          this.newMessagesCount = this.isChatOpen ? this.newMessagesCount : this.newMessagesCount + 1;
          response.data.suggestions = response.data.suggestions?.length ? response.data.suggestions : [
            "Explain Abstract of this paper",
            "Conclusions from the paper",
            "Results of the paper",
            "Methods used in this paper"
          ]

          this.onMessageWasSent({ author: 'assistant', type: 'html', data: { text: (marked(this.escapeHtml(response.data.text))) }, suggestions: response.data.suggestions })
          this.setCredit(response.data.remain_credit)

        } catch (e) {
          console.log(e)
          this.showTypingIndicator = '';
          this.newMessagesCount = this.isChatOpen ? this.newMessagesCount : this.newMessagesCount + 1;
          this.onMessageWasSent({ author: 'assistant', type: 'text', data: { text: e.response ? e.response.data.message : e.message + ' ' } })
          return false;
        }
      } else {
        return false;
      }


    },
    onMessageWasSent(message) {
      // called when the user sends a message
      this.messageList = [...this.messageList, message]
    },
    openChat() {
      // called when the user clicks on the fab button to open the chat
      this.isChatOpen = true
      this.newMessagesCount = 0
    },
    closeChat() {
      // called when the user clicks on the botton to close the chat
      this.isChatOpen = false
    },
    handleScrollToTop() {
      // called when the user scrolls message list to top
      // leverage pagination for loading another page of messages
    },
    handleOnType() {
      console.log('Emit typing event')
    },
    editMessage(message) {
      const m = this.messageList.find(m => m.id === message.id);
      m.isEdited = true;
      m.data.text = message.data.text;
    }
  },
  created() {
  },
  mounted() {
  },
  watch: {
    id: {
      immediate: true,
      handler(to) {
        this.getEntityData()
      }
    }
  }
}
</script>

<style scoped lang="scss"></style>
