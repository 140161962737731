<template>
  <div>
    <!--    <v-btn v-if="userId" icon to="/me/searches">-->
    <!--      <v-icon>mdi-folder-outline</v-icon>-->
    <!--    </v-btn>-->

    <div v-if="userId">

      <v-btn  :href="userCredit != 'time'?'https://search.paperify.org/paperify_backend/public/subscriptions':'#'" rounded v-if="userCredit == 'time' || userCredit != 0" color="primary">{{
        userCredit
          == 'time' ? 'active' : (userCredit !=
            'none' ?
            userCredit + ' credit(s)' : 'subscriptions') }}</v-btn>

      <v-btn rounded href="https://search.paperify.org/paperify_backend/public/api/subscriptions" v-if="userCredit === 0" color="primary">
        subscriptions
      </v-btn>
      <!-- ya purcheas ya active ya remain credit: 10-->
      <!-- ya purcheas ya active ya remain credit: 10-->

      <v-menu rounded offset-y>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on">
            <v-icon>mdi-account-outline</v-icon>
          </v-btn>
        </template>
        <v-list>
          <div class="py-2 px-4">
            {{ userName }}
          </div>
          <div class="pb-2 px-4 body-2 grey--text">
            {{ userEmail }}
          </div>
          <v-divider></v-divider>
          <v-list-item exact-path to="/me/searches">
            <v-list-item-icon>
              <v-icon>mdi-folder-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              Saved Searches
            </v-list-item-content>
          </v-list-item>

          <v-list-item exact-path href="https://search.paperify.org/paperify_backend/public/subscriptions">
            <v-list-item-icon>
              <v-icon>mdi-cash</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              buy credit or subscription
            </v-list-item-content>
          </v-list-item>

          <!--        <v-list-item exact-path to="/me">-->
          <!--          <v-list-item-icon>-->
          <!--            <v-icon>mdi-account-outline</v-icon>-->
          <!--          </v-list-item-icon>-->
          <!--          <v-list-item-content>-->
          <!--            Account settings-->
          <!--          </v-list-item-content>-->
          <!--        </v-list-item>-->

          <v-divider />
          <v-list-item @click="localLogout">
            <v-list-item-icon>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              Log out
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>

    </div>
    <div class="" v-else>
      <template v-if="$vuetify.breakpoint.mobile && showLoginRegister">
        <v-menu offset-y>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on">
              <v-icon>mdi-menu</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="setIsSignupDialogOpen(true)">
              <v-list-item-icon>
                <v-icon>mdi-account-plus</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="font-weight-bold">
                  Sign up
                </v-list-item-title>
                <v-list-item-subtitle>
                  Create a new account
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="setIsLoginDialogOpen(true)">
              <v-list-item-icon>
                <v-icon>mdi-account-arrow-right</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  Log in
                </v-list-item-title>
                <v-list-item-subtitle>
                  Access your existing account
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
      <template v-else-if="showLoginRegister">
        <v-btn text rounded @click="setIsLoginDialogOpen(true)">
          Log in
        </v-btn>
        <v-btn rounded text @click="setIsSignupDialogOpen(true)">
          Sign up
        </v-btn>
      </template>

    </div>

    <user-signup />
    <user-login />
  </div>
</template>

<script>

import { mapActions, mapGetters, mapMutations } from "vuex";
import UserSignup from "./UserSignup.vue";
import UserLogin from "./UserLogin.vue";

export default {
  name: "UserToolbarMenu",
  components: {
    UserSignup,
    UserLogin,
  },
  props: {},
  data() {
    return {
      foo: 42,
      dialogs: {
        userSignup: false,
        userLogin: false,
      },
      showLoginRegister: true
    }
  },
  computed: {
    ...mapGetters([

    ]),
    ...mapGetters("user", [
      "userName",
      "userEmail",
      "userCredit",
      "userIsNone",

      "userId",
      "isSignupDialogOpen",
      "isLoginDialogOpen"
    ]),
  },

  methods: {
    ...mapMutations([
      "snackbar",
    ]),
    ...mapMutations("user", [
      "logout",
      "setIsSignupDialogOpen",
      "setIsLoginDialogOpen",
    ]),
    ...mapActions("user", [
      "loginUserByAuth",
    ]),
    ...mapActions([]),
    localLogout() {
      this.logout()
      // this.$router.push("/")
      this.snackbar("You're logged out")
    },
    goToSavedSearches() {
      this.$router.push("/me/searches")
    }


  },
  created() {
  },
  updated() {
    const auth = this.$route.query.auth;
    const email = this.$route.query.email;
    const uid = this.$route.query.uid;

    // Check if all required query parameters are present
    if (auth && email && uid) {
      this.showLoginRegister = false;
    } else {
      this.showLoginRegister = true;
    }
  },
  async mounted() {
    const auth = this.$route.query.auth;
    const email = this.$route.query.email;
    const uid = this.$route.query.uid;

    // Check if all required query parameters are present
    if (auth && email && uid) {
      this.showLoginRegister = false;
    } else {
      this.showLoginRegister = true;
    }
  },
  watch: {
    isOpen(to, from) {
    },
  }
}
</script>

<style scoped lang="scss"></style>
